import nails from '../../assets/nails.png';

const Layout = () => {
  return (
    <div className='relative pt-20 min-h-full h-full bg-black/70'>
      <div
        className={`flex w-full min-h-full h-full relative items-center justify-center flex-col`}
      >
        <div className='flex flex-col items-end'>
          <div className='flex text-2xl'>GTA Home Spa Nails</div>
          <div className='flex'>
            <sub>By Artist Lucy</sub>
          </div>
        </div>
        <img alt='lucy nails' src={nails} className='h-1/4' />
      </div>
    </div>
  );
};

export default Layout;
