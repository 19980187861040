import { useEffect } from 'react';
import { logEvent } from 'firebase/analytics';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from './components/Layout';
import { analytics } from './firebase';

import './output.css';
import 'react-calendar/dist/Calendar.css';

function App() {
  useEffect(() => {
    logEvent(analytics, 'app_launched');
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<Layout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
